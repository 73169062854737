import React from "react"
import styled from "styled-components"
import styles from "./License.module.css"

const Section = styled.section`
  display: flex;
  flex-direction: column;
`

const Link = styled.a`
  color: #fff;
  &:hover {
    color: #fff;
  }
`

const License = () => {
  const year = new Date().getFullYear()
  return (
    <Section className={styles.component}>
      <div className={styles.text}>
        {`
        (c) Sunpulse K.K., ${year}

        Except as otherwise provided in the header of a specific source code
        file in this repository: (a) all intellectual property (including all
        source code, designs and protocols) contained in this repository has been
        published for informational purposes only; (b) no license, right of
        reproduction or distribution or other right with respect thereto is
        granted or implied; and (c) all moral, intellectual property and other
        rights are hereby reserved by the copyright holder.

        THE SOFTWARE AND INTELLECTUAL PROPERTY INCLUDED IN THIS REPOSITORY
        IS PROVIDED BY THE COPYRIGHT HOLDERS "AS IS," AND ANY EXPRESS
        OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED
        WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE
        ARE DISCLAIMED. IN NO EVENT SHALL THE COPYRIGHT HOLDER BE LIABLE FOR
        ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
        DAMAGES ARISING IN ANY WAY OUT OF THE USE OF THIS SOFTWARE OR
        INTELLECTUAL PROPERTY (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT
        OF SUBSTITUTE GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR
        BUSINESS INTERRUPTION), HOWEVER CAUSED OR CLAIMED (WHETHER IN CONTRACT,
        STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR OTHERWISE)), EVEN IF
        SUCH DAMAGES WERE REASONABLY FORESEEABLE  OR THE COPYRIGHT HOLDERS WERE
        ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
        `}
      </div>
    </Section>
  )
}

export default License
