import React from "react"
import styled from "styled-components"
import Demo from "./Icons/Demo"
import mysolar from "../assets/img/mysolar.png"

const Section = styled.section`
  padding: 30px 0 225px;
`
const SectionTitle = styled.h2`
  font-size: 26px;
  font-weight: 300;
  line-height: normal;
  color: #1b5cce;
  text-align: center;
  margin-bottom: 2px;
`

const SubTitle = styled.h5`
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  text-align: center;
  color: #aeaeae;
  margin-bottom: 25px;
  @media (min-width: 992px) {
    margin-bottom: 50px;
  }
`

const Image = styled.img`
  width: 200px;
  margin: 40px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`

const Features = () => {
  return (
    <Section id="features">
      <div className="container">
        <SectionTitle>Used By</SectionTitle>
        <Row>
          <a href="https://www.mysolar.co.jp/" target="__blank">
            <Image src={mysolar} />
          </a>
        </Row>
        <SubTitle>And more to come</SubTitle>
      </div>
    </Section>
  )
}

export default Features
