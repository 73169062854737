import React, { useEffect, Fragment } from "react"
import AOS from "aos"
import $ from "jquery"

import Header from "./components/Header"
import Home from "./components/Home"
import About from "./components/About"
import Features from "./components/Features"
// import Pricing from "./components/Pricing"
import UsedBy from "./components/UsedBy"
import Contact from "./components/Contact"
import License from "./components/License"

import "aos/dist/aos.css"
import "./assets/styles/main.scss"

const App = () => {
  useEffect(() => {
    AOS.init({ once: true })

    let navElement = $("nav")

    $(function () {
      $(window).scrollTop() > navElement.innerHeight()
        ? navElement.addClass("sticky")
        : navElement.removeClass("sticky")
    })
    $(window).on("scroll", function () {
      $(window).scrollTop() > navElement.innerHeight()
        ? navElement.addClass("sticky")
        : navElement.removeClass("sticky")
    })
  })

  return (
    <Fragment>
      <Header />
      <main>
        <Home />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginTop: 75,
            marginBottom: 75,
          }}
        >
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/r5XtU5KoJV8"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <About />
        <Features />
        {/*}
        <Pricing />
        */}
        <UsedBy />
        <Contact />
        <License />
      </main>
    </Fragment>
  )
}

export default App
