import React from "react"
import styled from "styled-components"

const Section = styled.section`
  padding: 30px 0 225px;
`
const SectionTitle = styled.h2`
  font-size: 26px;
  font-weight: 300;
  line-height: normal;
  color: #1b5cce;
  text-align: center;
  margin-bottom: 2px;
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 30px;
  &:hover {
    transform: translateY(-5px) !important;
  }

  @media (min-width: 992px) {
    margin-bottom: 0;
  }
`

const IconWrap = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 150px;
  border: solid 1px #eff2f9;
  background-color: #f8faff;
  margin-bottom: 30px;
  position: relative;
  > svg {
    transition: all 0.3s ease-in;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &:hover {
    > svg {
      transform: translate(-50%, -50%) rotateY(360deg);
    }
  }
`

const BoxTitle = styled.h4`
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
  color: #5273c7;
`

const Text = styled.p`
  font-size: 12px;
  font-weight: normal;
  line-height: 1.58;
  color: #8f8f8f;
  margin-bottom: 0;
  max-width: 350px;
`

const About = () => {
  const width = window.innerWidth
  return (
    <Section id="about">
      <div className="container">
        <SectionTitle>How It Works</SectionTitle>
        <div className="row">
          <div className="col-lg-4">
            <Box data-aos={width >= 1400 ? "fade-right" : "fade-up"}>
              <BoxTitle>1. Install Hardware</BoxTitle>
              <Text>
                Connect our trusted hardware at your data source and switch it
                on.
              </Text>
            </Box>
          </div>
          <div className="col-lg-4">
            <Box data-aos="fade-up">
              <BoxTitle>2. Publish IoT Data</BoxTitle>
              <Text>
                Our device will cryptographically sign every input from your
                data source, and publish it onto our platform.
              </Text>
            </Box>
          </div>
          <div className="col-lg-4">
            <Box data-aos={width >= 1400 ? "fade-left" : "fade-up"}>
              <BoxTitle>3. Monitor</BoxTitle>
              <Text>Login to our platform and monitor your data.</Text>
            </Box>
          </div>
        </div>

        <div className="row">
          <div
            style={{
              marginTop: 75,
              flex: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/2FK0alKW87k"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default About
