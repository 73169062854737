import React from "react"
import styled from "styled-components"
import styles from "./Contact.module.css"

const Section = styled.section`
  display: flex;
  flex-direction: column;
`

const Link = styled.a`
  color: #fff;
  &:hover {
    color: #fff;
  }
`

const Contact = () => {
  const year = new Date().getFullYear()

  return (
    <Section className={styles.component}>
      <div>Sunpulse K.K. © Copyright {year}, All rights reserved. Japan.</div>
      <Link href="https://www.sunpulse.net" target="__blank">
        www.sunpulse.net
      </Link>
      <div>Contact</div>
      <div>info at sunpulse.net</div>
    </Section>
  )
}

export default Contact
